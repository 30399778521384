import { CabButton } from "@CabComponents/CabButton";
import CabCollapseMenu from "@CabComponents/CabCollapseMenu";
import { CabIcon } from "@CabComponents/CabIcon";
import { PopoverProps, SxProps, useMediaQuery, styled } from "@mui/material";
import { ReactElement, useMemo } from "react";
import { CSVLink } from "react-csv";
import colors from "../../colors";
import theme from "../../theme";
import { isMobile } from "../../utils/screenSizeUtils";
import { 
  IoShareSocialOutline, IoPencilOutline, IoCheckboxOutline, IoCopyOutline, IoTrashOutline, IoDownloadOutline, 
  IoEyeOffOutline, IoEyeOutline 
} from "react-icons/io5";

export interface ActionMenuProps {
  target?: ReactElement | string
  passedAnchorEl?: Element | null
  passedAnchorOrigin?: PopoverProps["anchorOrigin"]
  passedTransformOrigin?: PopoverProps["transformOrigin"]
  onClose?: () => void
  onEdit?: () => void;
  onSeeResults?: () => void;
  onDelete?: () => void;
  onDuplicate?: () => void;
  onShare?: () => void;
  onExport?: () => string[][];
  onShowMore?: () => void;
  setIsOpen?: (open: boolean) => void;
  filename?: string;
  showMore?: boolean;
  sx?: SxProps;
  isPoll?: boolean;
  horizontalPosition?: number | 'left' | 'right' | 'center';
}

const StyledGroupButton = styled(CabButton, {label: "StyledGroupButton"})({
  justifyContent: 'start', 
  color: colors.black900, 
  paddingBottom: 8, 
  paddingTop: 8, 
  paddingLeft: 15, 
  paddingRight: 15, 
  width: '100%'
});

export const ActionMenu = ({ 
  onEdit, onSeeResults, onDelete, onDuplicate, onShare, onExport, onShowMore, showMore, filename, sx, passedAnchorEl, 
  onClose, passedAnchorOrigin, passedTransformOrigin, target, isPoll, setIsOpen, horizontalPosition
}: ActionMenuProps): ReactElement => {
  const exportData = useMemo(() => {
    if (onExport) {
      return onExport();
    } else {
      return [];
    }
  }, [onExport]);

  const sanitizedFilename = filename && filename.replace(/[/\\?%*:|"<>.]/g, '') + ".csv";
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <CabCollapseMenu
      sx={{ ...sx }}
      onClose={onClose}
      setIsOpen={setIsOpen}
      passedAnchorOrigin={passedAnchorOrigin}
      passedTransformOrigin={passedTransformOrigin}
      passedAnchorEl={passedAnchorEl}
      buttonGroupSx={{paddingTop: 1, paddingBottom: 1, minWidth: isSmDown ? "120px" : "190px"}}
      buttonGroupColor="inherit"
      buttonGroupVariant="outlined"
      target={target}
      buttons={<>
        {onShare && (
          <StyledGroupButton 
            buttonType="text"
            onClick={() => onShare()} 
            icon={<CabIcon Icon={IoShareSocialOutline} color={colors.black900}/>}
          >
            {isSmDown ? 'Share' : isPoll ? 'Share Poll' : 'Share Meeting'}
          </StyledGroupButton>
        )}
        {!isMobile() && onEdit && (
          <StyledGroupButton
            buttonType="text"
            onClick={() => onEdit()} 
            icon={<CabIcon Icon={IoPencilOutline} color={colors.black900}/>}
          >
            { isSmDown ? 'Edit' : isPoll ? 'Edit Poll' : 'Edit Meeting'}
          </StyledGroupButton>
        )}
        {onSeeResults && isPoll && (
          <StyledGroupButton 
            buttonType="text"
            onClick={() => onSeeResults()} 
            icon={<CabIcon Icon={IoCheckboxOutline} color={colors.black900}/>}
          >
            {'See Results'}
          </StyledGroupButton>
        )}
        {onDuplicate && (
          <StyledGroupButton 
            buttonType="text"
            onClick={() => onDuplicate()} 
            icon={<CabIcon Icon={IoCopyOutline} color={colors.black900}/>}
          >
            {isSmDown ? 'Duplicate' : isPoll ? 'Duplicate Poll' : 'Duplicate Meeting'}
          </StyledGroupButton>
        )}
        {onDelete && (
          <StyledGroupButton 
            buttonType="text"
            onClick={() => onDelete()} 
            icon={<CabIcon Icon={IoTrashOutline} color={colors.black900}/>}
          >
            {isSmDown ? 'Delete' : isPoll ? 'Delete Poll' : 'Delete Meeting Record'}
          </StyledGroupButton>
        )}
        {onExport && sanitizedFilename && (
          <CSVLink data={exportData} filename={sanitizedFilename} style={{textDecoration: 'none'}}>
            <StyledGroupButton 
              buttonType="text"
              icon={<CabIcon Icon={IoDownloadOutline} color={colors.black900}/>}
            >
              Export
            </StyledGroupButton>
          </CSVLink>
        )}
        {onShowMore && (
          showMore
            ? <StyledGroupButton
              buttonType="text"
              onClick={() => onShowMore()}
              icon={<CabIcon Icon={IoEyeOffOutline} color={colors.black900}/>}
            >
              Hide Times
            </StyledGroupButton>
            : <StyledGroupButton
              buttonType="text"
              onClick={() => onShowMore()}
              icon={<CabIcon Icon={IoEyeOutline} color={colors.black900}/>}
            >
              See Times
            </StyledGroupButton>
        
        )}
      </>}
      popOverTitle=""
      popOverAnchorOrigin={{
        horizontal: horizontalPosition || -100,
        vertical:"bottom"
      }}
    />
  );
};